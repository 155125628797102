@media (max-width: 1999.98px) {
  .breakpoint-indicator {
    border-bottom: 1px solid red;
  }
}

@media (max-width: 1599.98px) {
  .main-title {
    font-size: 42px;
    font-weight: 400;
    line-height: 60px;
  }

  .second-title {
    font-size: 26px;
    font-weight: 420;
  }

  .breakpoint-indicator {
    border-bottom: 1px solid orange;
  }
}

@media (max-width: 1199.98px) {
  .main-title {
    font-size: 35px;
    font-weight: 400;
    line-height: 48px;
  }

  .second-title {
    font-size: 24px;
    font-weight: 420;
  }

  .breakpoint-indicator {
    border-bottom: 1px solid yellow;
  }
}

@media (max-width: 991.98px) {
  .main-title {
    font-size: 30px;
    font-weight: 400;
    line-height: 40px;
  }

  .breakpoint-indicator {
    border-bottom: 1px solid green;
  }
}

@media (max-width: 767.98px) {
  .breakpoint-indicator {
    border-bottom: 1px solid blue;
  }
}

@media (max-width: 575.98px) {
  .breakpoint-indicator {
    border-bottom: 1px solid violet;
  }
}

@media screen and (min-width: 0px) and (max-width: 991.98px) {
  #navmenu-horizontal {
    display: none;
  }

  #navmenu-drawer {
    display: block;
  }

  .center-for-small-screen {
    text-align: center;
  }
}

@media screen and (min-width: 992px) {
  #navmenu-horizontal {
    display: block;
  }

  #navmenu-drawer {
    display: none;
  }

  .center-for-small-screen {
  }
}
