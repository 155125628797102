@import url("https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100..900;1,100..900&display=swap");

body {
  margin: 0;
  padding: 0;
  background-color: #fff;
  /*font-family: -apple-system, BlinkMacSystemFont, "Jost", "Segoe UI", "Roboto", "Oxygen",*/
  /*  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",*/
  /*  sans-serif;*/
  /*-webkit-font-smoothing: antialiased;*/
  /*-moz-osx-font-smoothing: grayscale;*/
}

h1 {
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.clickable {
  cursor: pointer;
}

.breakpoint-indicator {
  border-bottom: 0;
}

.masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  margin-left: -12px; /* gutter size offset */
  width: auto;
}

.masonry-grid_column {
  padding-left: 12px; /* gutter size */
  background-clip: padding-box;
}

.masonry-grid_column > div {
  /* change div to reference your elements you put in <Masonry> */
  margin-bottom: 12px;
}

.main-title {
  font-size: 45px;
  font-weight: 400;
  line-height: 60px;
}

.second-title {
  color: #168dff;
  font-size: 30px;
  font-weight: 420;
}

.third-title {
  font-size: 25px;
  font-weight: 300;
}

.main-title-light {
  font-size: 45px;
  font-weight: 400;
  line-height: 60px;
  color: #ffffff;
}

.second-title-light {
  color: #ffffff;
  font-size: 30px;
  font-weight: 420;
}

.third-title-light {
  font-size: 25px;
  font-weight: 300;
  color: #ffffff;
}

.second-title-accent {
  color: #ff6f00;
  font-size: 30px;
  font-weight: 420;
}

.top-nav-link {
  color: #000;
  font-weight: 400;
  font-size: 18px;
}

.top-nav-link:hover {
  color: #000;
  text-decoration: underline;
}

.marketing-button {
  font-weight: 600;
  font-size: 18px;
  height: 2em;
}

.uppercase-title {
  text-transform: uppercase;
  font-size: 25px;
  font-weight: 500;
}

.footer-text {
  font-size: 1.25rem;
  font-weight: 400;
  color: #ffffff;
}

a.footer-text:hover {
  color: #ffffff;
  text-decoration: underline;
}

.btn--accent {
  font-weight: 600;
  font-size: 18px;
  height: 2em;
  background-color: #ff6f00;
}

.btn--accent:hover {
  background-color: #d1753a !important;
}

.screenshot {
  border: 1px solid rgb(0, 0, 0, 0.1);
  transition: transform 0.2s;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.screenshot:hover {
  transform: scale(1.01); /* Slightly enlarges the image on hover */
}

.iframe-wrapper {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%; /* 16:9 aspect ratio */
  height: 0;
}

.iframe-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
}

@media screen and (max-width: 992px) {
  .hide-on-small-screen {
    display: none;
  }
}